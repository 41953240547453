'use client';

import { Disconnect } from '@ahamove/icons/empty';
import { ResultBlock } from '@ahm/ui/components/result-block';
import { Button } from '@ahm/ui/components/ui/button';
import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { ROUTES } from '@/config/router';
import { MainLayout } from '@/layouts/main';
import { Link } from '@/navigation';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const tSystem = useTranslations('system');

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <MainLayout>
      <ResultBlock
        icon={Disconnect}
        title={tSystem('error.title')}
        description={tSystem('error.errorHint1')}
      >
        {error.digest ? (
          <p className="text-neutral-70 dark:text-neutral-30 text-xs font-semibold">
            {tSystem('error.errorDigest')}: <code>{error.digest}</code>
          </p>
        ) : null}

        <div className="!mt-6 flex flex-row flex-wrap items-center justify-center gap-4">
          <Button
            variant="outline"
            size="sm"
            className="bg-muted min-w-48"
            onClick={() => reset()}
          >
            {tSystem('error.refreshThePage')}
          </Button>
          <Link href={ROUTES.home} passHref>
            <Button variant="outline" size="sm" className="min-w-48">
              {tSystem('links.backToHomepage')}
            </Button>
          </Link>
        </div>
      </ResultBlock>
    </MainLayout>
  );
}
