import { jsx as e } from "react/jsx-runtime";
const l = (o) => /* @__PURE__ */ e(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1rem",
    height: "1rem",
    fill: "none",
    viewBox: "0 0 20 20",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fill: "#111827",
        fillRule: "evenodd",
        d: "M5.05 4.05a7 7 0 1 1 9.9 9.9L10 18.9l-4.95-4.95a7 7 0 0 1 0-9.9M10 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4",
        clipRule: "evenodd"
      }
    )
  }
), i = l;
export {
  i as default
};
