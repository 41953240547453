import { jsxs as a, jsx as c } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1rem",
    height: "1rem",
    fill: "none",
    viewBox: "0 0 164 148",
    ...l,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          stroke: "#CDD6E1",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.386,
          d: "M52.2 127.694h92.171M153.372 127.694h9.128"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#B8BFCD",
          d: "M54.219 120.691a5.084 5.084 0 1 0 0-10.168 5.084 5.084 0 0 0 0 10.168M103.992 99.539c.063 8.034.753 18.202 1.005 19.269-.063 1.13-2.448 4.707-4.394 2.259s-5.9-7.72-4.08-17.637c1.82-9.854 7.469-3.891 7.469-3.891"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#EAEEF9",
          d: "M53.529 120.628c0 .063 0 .189.062.251 0 .063.063.189.063.251.063.063.063.189.126.251.062.063.125.126.188.251.314.44.879.816 1.506 1.193.126.063.314.126.44.188.062.063.188.063.25.126.063.063.189.063.252.125l.25.063c.315.126.691.251 1.068.314.188.063.314.063.502.126.126 0 .251.062.44.062.125 0 .25.063.376.063 1.004.126 1.946.063 2.762-.251 2.824-1.067 3.954-3.264 3.954-3.264s.753.188 1.946.314c2.26.314 6.025.753 9.289.439 1.067-.125 2.197-.376 3.39-.69.376-.126.752-.251 1.192-.377.753-.251 1.444-.565 2.197-.878a22.9 22.9 0 0 0 5.46-3.39c.189-.125.377-.314.565-.439.879-.816 1.632-1.695 2.197-2.573 1.13-1.946 2.636-3.578 4.268-5.147 2.448-2.323 5.335-4.331 7.971-6.465.816-.628 1.632-1.318 2.385-1.946 2.448-2.134 4.645-4.645 6.151-7.532 1.632-3.138 1.632-6.088 1.381-9.54 0-.189-.376-2.448-.251-2.51a5.97 5.97 0 0 0 2.385-3.14c1.005-2.635.628-6.025-.565-8.221a7 7 0 0 0-.376-.69c-1.256-2.198-2.762-4.771-4.457-6.466l-3.075-3.075H81.083s-.314 1.82-.754 4.08c-.062.188-.062.376-.125.564-.063.377-.125.754-.251 1.13-.063.188-.063.377-.126.565 0 .063 0 .063-.062.126-.063.439-.189.816-.251 1.255-.251 1.255-.565 2.385-.942 3.327 0 .062-.063.188-.063.25-.188.503-.376.942-.565 1.256-1.506 2.448-9.163 9.478-14.498 13.432s-7.783 10.67-9.164 21.528c-1.57 10.796-1.004 12.993-.753 15.064M101.733 117.678c3.577-.376 3.515 0 5.523 0 2.009 0 2.511 1.004 3.264 1.946.439.628 1.067 2.448 1.444 3.766.251.753-.314 1.443-1.067 1.506-2.26 0-6.34.063-7.03 0-1.004-.125-3.139-.376-4.08-1.946-.314-.564-.502-1.318-.69-2.071-.251-1.443.69-2.887 2.196-3.075z"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#BFC8D6",
          d: "M53.529 120.628c.188 2.071 5.335 4.017 8.159 2.95 2.887-1.067 3.954-3.264 3.954-3.264s6.214 1.255 11.235.816c3.766-.377 8.85-2.573 12.177-5.398l-.44-9.54s-.376-8.348-.753-11.988c-.314-3.64 2.574-3.578 2.448-8.285-.188-4.708 1.695-13.056 4.143-20.713l-8.536-6.402-5.838 3.326c-.565 3.139-1.506 7.093-2.322 8.536-1.506 2.448-9.164 9.478-14.499 13.432s-7.783 10.67-9.164 21.529c-1.38 10.733-.816 12.93-.565 15.001",
          opacity: 0.3
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#BFC8D6",
          d: "M53.528 120.628c0 .063 0 .189.063.252 0 .062.063.188.063.251.063.062.063.188.125.251a.9.9 0 0 1 .189.251c.314.439.878.816 1.506 1.192.126.063.314.126.44.189.062.062.188.062.25.125.063.063.189.063.252.126l.25.062c.315.126.691.251 1.068.314.188.063.314.063.502.126.879-.565 1.38-.942 1.38-.942l-.376-2.259c-.125-.691-.25-1.632-.44-2.888-.815-5.209-.25-15.503 6.717-15.189-.754-5.272 2.134-11.11 4.644-14.812 2.574-3.641 4.457-1.633 6.653.878.252-1.067-.878-5.335-2.259-9.1 2.824 3.389 5.335 4.581 12.051 5.209-3.013-2.51-3.39-3.829-5.837-9.352 3.075 1.443 9.352 3.138 13.118 3.64 9.101 1.193 16.947.753 22.219-2.448 1.004-2.636.628-6.025-.565-8.222-.126-.251-.251-.502-.377-.69-4.833-.44-13.055-1.13-20.775-1.82-5.649-.503-11.047-1.005-14.687-1.319 0 .063 0 .063-.063.126-.063.439-.188.816-.251 1.255-.251 1.255-.565 2.385-.942 3.327 0 .062-.062.188-.062.25-.189.503-.377.942-.565 1.256-1.507 2.448-9.164 9.478-14.5 13.432-5.334 3.954-7.782 10.67-9.163 21.528-1.444 10.733-.879 12.93-.628 15.001",
          opacity: 0.4
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#B8BFCD",
          d: "M89.619 124.332s-.502-7.595-.565-8.599c-.063-.691-4.142 2.573-7.908-4.394s-4.331-8.222-4.206-9.98c-1.13 8.85 2.385 14.813 3.076 18.014.69 3.201 1.318 3.076 1.318 3.076l6.34 1.255z"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#EAEEF9",
          d: "M83.342 119.184c3.578-.439 3.515 0 5.524 0s2.51 1.067 3.263 2.071c.44.628 1.067 2.636 1.444 4.08.251.816-.314 1.569-1.067 1.569-2.26 0-7.28-.063-7.971-.125-1.004-.126-2.197-.314-3.076-2.009-.314-.627-.502-1.443-.69-2.259-.251-1.57.69-3.076 2.197-3.327zM67.023 120.251c1.632 1.005 2.573 3.39 3.076 5.084.25.816-.314 1.57-1.068 1.57-2.259 0-7.28-.063-7.97-.126-3.955-.502-4.394-4.833-1.382-6.528 1.883-1.129 5.461-1.192 7.344 0M110.488 71.438c-.56.523-1.19.994-1.829 1.402a24 24 0 0 1-2.761 1.535c-2.912 1.367-6.01 2.316-9.285 2.467-2.707.133-5.583-.027-8.237-.853a16 16 0 0 1-2.68-1.013c-4.705-2.308-7.385-4.208-7.757-9.48-.373-5.273-.132-9.365-.132-9.365 0-.444.479-1.083.612-1.482.249-.48.426-1.012.675-1.492.435-.95.923-1.97 1.43-2.866 1.118-1.935 2.53-3.595 4.323-4.802 2.894-1.935 6.4-2.689 9.835-3.052 2.495-.293 5.078-.408 7.581.25 2.174.576 4.038 1.642 5.707 3.116 1.81 1.58 3.23 3.533 4.819 5.335 0 0 1.375 3.861 2.493 9.027 1.118 5.167-.933 7.678-4.794 11.273"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#BFC8D6",
          d: "M89.602 63.26c-.657.728-1.27 3.098-4.882 2.218-5.13-1.296-5.538-7.705-3.15-10.075 4.27-4.286 9.418-3.31 10.838 1.307 1.464 4.926-1.483 5.157-2.806 6.55"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M79.574 48.08c1.376-1.908 3.409-1.437 2.707.755-1.145 3.524-3.436 5.246-3.615 18.205-.053 2.29-7.882-2.362-8.21-10.43-.088-1.51 1.732-2.087 1.732-2.087 1.092-.346 5.22-3.407 7.386-6.443"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#BFC8D6",
          d: "M108.029 69.316a8.45 8.45 0 0 1-.569 4.456c-.754.488-1.58.923-2.405 1.357-2.557 1.19-5.29 2.024-8.06 2.165-2.326.142-4.891-.062-7.208-.746-1.065-1.243-1.713-2.672-1.952-4.35-.727-5.095 3.223-9.843 8.815-10.642 5.53-.789 10.652 2.665 11.379 7.76"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#fff",
          d: "M86.709 62.975a3.703 3.703 0 1 0-1.047-7.332 3.703 3.703 0 0 0 1.047 7.332"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M89.532 59.212c.222 1.554-.897 3.045-2.45 3.266s-3.045-.896-3.267-2.45c.285.213.622.355.995.302a1.326 1.326 0 0 0 1.118-1.491c-.097-.683-.736-1.163-1.429-1.127a2.9 2.9 0 0 1 1.767-.95c1.615-.23 3.035.835 3.266 2.45"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#fff",
          d: "M107.089 60.067a3.703 3.703 0 1 0-1.046-7.332 3.703 3.703 0 0 0 1.046 7.332"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M103.326 57.244c.222 1.553 1.713 2.672 3.266 2.45 1.554-.222 2.672-1.713 2.451-3.266-.213.284-.497.514-.87.568a1.325 1.325 0 0 1-1.491-1.119c-.098-.684.381-1.323 1.056-1.482a2.9 2.9 0 0 0-1.962-.418 2.88 2.88 0 0 0-2.45 3.267M96.739 62.178q-.745.106-1.465.399c-.594.275-1.482.719-1.367 1.526.142.995 2.636 1.59 3.258 1.5.621-.088 2.858-1.295 2.707-2.351-.142-.994-1.118-1.172-1.944-1.18-.381-.01-.825-.01-1.189.106"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "m97.387 65.383-.231-.284-.142.337s.124 2.201-.276 2.956c.373.39.684.346.8.266.195.035.505-.009.692-.48-.462-.567-.843-2.795-.843-2.795M84.686 51.472c-.834.373-1.544.728-2.095 1.313-.55.586-.94 1.403-1.074 2.246.009.062-.053.071-.115.08s-.071-.053-.071-.053c-.062-.435.053-.959.186-1.358.125-.462.382-.88.71-1.243a4.8 4.8 0 0 1 1.075-.914c.417-.187.905-.32 1.358-.258.07.054.142.107.026.187q.094-.014 0 0M105.75 48.465c.906.124 1.687.266 2.379.675.692.408 1.296 1.083 1.66 1.855.009.062.071.053.133.044.062-.008.053-.07.053-.07-.062-.436-.319-.906-.559-1.252-.248-.408-.612-.737-1.029-.995a4.8 4.8 0 0 0-1.287-.577c-.453-.062-.959-.053-1.376.133q-.08.106.026.187s-.062.009 0 0"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M109.31 43.201c-1.855-1.447-3.674-.426-2.387 1.482 2.085 3.063 4.766 4.075 8.563 16.467.692 2.184 6.907-4.473 4.964-12.31-.337-1.474-2.246-1.519-2.246-1.519-1.145-.027-5.965-1.811-8.894-4.12"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          stroke: "#AAB2C2",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: 3.181,
          d: "M2.5 129.102h34.27c1.067 0 1.255 1.569.188 1.757-9.665 2.009-21.34 2.825-14.624 6.905 11.423 6.904 29.249 10.419 50.464 6.465 19.08-3.515 19.331-55.234 20.21-69.42.189-2.51 2.26-4.519 4.77-4.519h1.632c2.636 0 4.833 2.135 4.833 4.77v8.537"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M104.522 71.53c.721 3.054-1.765 4.543-5.125 5.335-3.36.793-6.25.572-6.97-2.483-.721-3.054 1.39-6.132 4.75-6.924s6.625 1.017 7.345 4.071M101.293 99.54a1.125 1.125 0 0 1-1.129-1.13v-4.394c0-.628.502-1.13 1.129-1.13.628 0 1.13.502 1.13 1.13v4.394c0 .69-.502 1.13-1.13 1.13M107.13 99.54c-.627 0-1.13-.503-1.13-1.13v-4.394c0-.628.503-1.13 1.13-1.13.628 0 1.13.502 1.13 1.13v4.394c0 .69-.502 1.13-1.13 1.13"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M110.08 94.08H98.531V92.76c0-3.201 2.574-8.724 5.775-8.724s5.774 5.523 5.774 8.724z"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#EAEEF9",
          d: "M158.85 96.276h-23.789a1.754 1.754 0 0 1-1.757-1.758V69.161c0-1.005.816-1.758 1.757-1.758h23.789c1.004 0 1.757.816 1.757 1.758v25.357c.063.942-.753 1.758-1.757 1.758"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#CCD3DE",
          d: "M155.9 81.338c0 2.95-1.444 5.648-3.641 7.28h-10.733a9.04 9.04 0 0 1-3.64-7.28c0-2.95 1.444-5.65 3.64-7.281h10.733c2.197 1.694 3.641 4.33 3.641 7.28"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#989FB0",
          d: "M143.535 81.84a.83.83 0 0 1-.816-.817v-2.699c0-.44.377-.816.816-.816s.816.377.816.816v2.7c0 .439-.377.815-.816.815M150.125 81.84a.83.83 0 0 1-.816-.817v-2.699c0-.44.377-.816.816-.816s.816.377.816.816v2.7c0 .439-.377.815-.816.815M147.615 85.73h-1.632a.764.764 0 0 1-.754-.753v-.816c0-.878.691-1.569 1.57-1.569.878 0 1.569.69 1.569 1.57v.815a.763.763 0 0 1-.753.754"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#D2DAE7",
          d: "M136.191 71.231a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133M157.846 71.231a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134M136.191 94.643a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133M157.846 94.643a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fill: "#B8BFCD",
          d: "m86.362 0 3.848 1.406.998 25.162-2.09-.873zM89.307 28.556l1.9.92v2.473l-1.9-.775zM94.439 12.653l3.848 1.26v16.194l-2.138-.873zM96.34 31.949l1.947.872v2.328l-1.948-.728z"
        }
      )
    ]
  }
), M = t;
export {
  M as default
};
