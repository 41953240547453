import { AhafoodLogo } from '@ahamove/icons/order';
import { cn } from '@ahm/common-helpers';
import classes from './main-layout.module.css';
import LoginButton from '@/components/fb-login-btn';
import { Link } from '@/navigation';

export default function MainHeader() {
  return (
    <header className={cn(classes.header)}>
      <div className="container mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-4 px-6 py-2 sm:flex-row md:h-24 md:gap-6 md:px-6">
        <div className="flex w-full flex-1 flex-row flex-nowrap items-center">
          <Link className="flex flex-none items-center justify-start" href="/">
            <AhafoodLogo className="h-10 w-36 sm:h-[64px] sm:w-[259px]" />
            <h1 className="sr-only">Ahamove</h1>
          </Link>
        </div>
        <div className="m-0 w-full flex-none flex-nowrap sm:w-auto">
          <LoginButton />
        </div>
      </div>
    </header>
  );
}
