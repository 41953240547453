import { jsxs as i, jsx as l } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ i(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1rem",
    height: "1rem",
    fill: "none",
    viewBox: "0 0 20 20",
    ...t,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fill: "#111827",
          d: "M2.003 5.884 10 9.882l7.997-3.998A2 2 0 0 0 16 4H4a2 2 0 0 0-1.997 1.884"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fill: "#111827",
          d: "m18 8.118-8 4-8-4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2z"
        }
      )
    ]
  }
), e = a;
export {
  e as default
};
