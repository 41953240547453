import { cn } from '@ahm/common-helpers';
import { ScrollArea } from '@ahm/ui/components/ui/scroll-area';
import classes from './main-layout.module.css';

export default function MainNavbar() {
  return (
    <aside className={cn('inset-y z-20 flex flex-col', classes.navbar)}>
      <ScrollArea>Scrollable navbar content</ScrollArea>
    </aside>
  );
}
